import React, { useContext, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { SiteContext } from '../../state';
const StyledOverlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 99;
  justify-content: center;
  align-items: center;
  display: flex;
  img {
    width: auto;
    height: auto;
    max-height: 90%;
    max-width: 90%;
    border: solid 5px #fff;
    box-sizing: border-box;
  }
`;
interface Props {
  src: string;
}
const ImageModal = ({ src }: Props) => {
  const { dispatch } = useContext(SiteContext);

  const handleKeydown = useCallback((e?: any) => {
    if (e.keyCode === 27) {
      dispatch({ type: 'SET_IMAGE_MODAL_OPEN', payload: false });
    }
    if (e.keyCode === 9) {
      e.preventDefault();
    }
    if (e.keyCode === 39) {
      if (document.activeElement?.nextSibling) {
        const child = document.activeElement.nextSibling as HTMLButtonElement;
        child.focus();
        const imageElement = document.activeElement.childNodes[0].childNodes[2]
          .childNodes[1] as HTMLImageElement;
        dispatch({
          type: 'SET_IMAGE_MODAL_SRC',
          payload: imageElement.getAttribute('src'),
        });
      }
    }
    if (e.keyCode === 37) {
      if (document.activeElement?.previousSibling) {
        const child = document.activeElement
          .previousSibling as HTMLButtonElement;
        child.focus();
        const imageElement = document.activeElement.childNodes[0].childNodes[2]
          .childNodes[1] as HTMLImageElement;
        dispatch({
          type: 'SET_IMAGE_MODAL_SRC',
          payload: imageElement.getAttribute('src'),
        });
      }
    }
  }, []);
  useEffect(() => {
    document.addEventListener('keydown', handleKeydown);
    return () => {
      document.removeEventListener('keydown', handleKeydown);
    };
  }, []);
  return (
    <>
      <StyledOverlay
        onClick={() =>
          dispatch({ type: 'SET_IMAGE_MODAL_OPEN', payload: false })
        }
      >
        <img src={src} />
      </StyledOverlay>
    </>
  );
};

export default ImageModal;
