import React, { ReactNode, HTMLAttributes } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { siteColors, styleVariables } from '../../styles/Variables';

const StyledLinkButton = styled(Link)`
  border-radius: ${styleVariables.borderRadius};
  color: #fff;
  font-size: 1rem;
  padding: 10px 30px;
  height: auto;
  background-color: ${siteColors.primary};
  text-align: center;
  display: inline-block;
  text-decoration: none;
  transition: all 0.2s ease;
  &:hover {
    background-color: #aa0202;
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
  }
`;
interface Props extends HTMLAttributes<HTMLElement> {
  to: string;
  children: ReactNode;
}
const LinkButton = ({ to, children, ...rest }: Props) => {
  return (
    <StyledLinkButton to={to} {...rest}>
      {children}
    </StyledLinkButton>
  );
};

export default LinkButton;
