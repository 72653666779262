import React from 'react';
import styled from 'styled-components';
import heroImg from '../../assets/images/hero-image.jpg';

const StyledHero = styled.div`
  width: 100%;
  height: 100px;
  background-color: #333;
  box-sizing: border-box;
  background-image: url(${heroImg});
  background-size: cover;
  position: relative;
`;

const StyledHeroOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
`;

const SmallerHero = () => {
  return (
    <StyledHero>
      <StyledHeroOverlay />
    </StyledHero>
  );
};

export default SmallerHero;
