import { createGlobalStyle } from 'styled-components';
import { Colors } from '@adamwebster/fused-components';
import { siteColors } from './Variables';
export const GlobalStyles = createGlobalStyle`
    body{
        background-color: #f2f2f2;
        color: ${Colors.dark};
        font-family: 'Helvetica Neue', sans-serif;
        font-size: 100%;
        padding: 0;
        margin: 0;
        line-height: 1.5;
    }

    a{
        color: ${siteColors.primary};
    }
`;
