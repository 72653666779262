import React from 'react';
import styled from 'styled-components';
import heroImg from '../../assets/images/hero-image.jpg';
import { siteColors } from '../../styles/Variables';
import { LinkButton } from '../LinkButton';

const StyledHero = styled.div`
  width: 100%;
  height: 800px;
  background-color: #333;
  box-sizing: border-box;
  background-size: cover;
  position: relative;
  @media (max-width: 768px) {
    min-height: 600px;
  }
`;

const StyledHeroOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
`;

const StyledHeaderInner = styled.div`
  max-width: 980px;
  display: flex;
  margin: 0 auto;
  color: #fff;
  flex: 1 1;
  height: 100%;
  align-content: center;
  h1 {
    text-transform: uppercase;
    font-size: 3rem;
    font-weight: 500;
    @media (max-width: 768px) {
      text-align: center;
      margin-top: 150px;
      font-size: 3rem;
    }
    @media (max-width: 600px) {
      font-size: 2rem;
    }
    @media (max-width: 400px) {
      font-size: 1.5rem;
    }
  }
  @media (max-width: 768px) {
    flex-flow: column;
  }
`;

const StyledHeroMessage = styled.div`
  display: flex;
  justify-content: center;
  flex: 1 1;
  flex-flow: column;
  @media (max-width: 768px) {
    padding: 0 50px;
    margin-bottom: 50px;
  }
`;

const StyledHeroButton = styled(LinkButton)`
  background-color: ${siteColors.primary};
  font-size: 2rem;
  height: fit-content;
  padding: 10px 30px;
  text-transform: uppercase;
`;

const StyledOrderNowWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1;
  @media (max-width: 768px) {
    align-items: flex-start;
  }
`;

const Hero = () => {
  return (
    <StyledHero style={{ backgroundImage: `url(${heroImg})` }}>
      <StyledHeroOverlay>
        <StyledHeaderInner>
          <StyledHeroMessage>
            <h1>
              Hero Text That
              <br />
              Spans Two Lines
            </h1>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed
              nibh ac velit ultricies dignissim. Nulla pulvinar interdum tellus
              nec dignissim.
            </p>
          </StyledHeroMessage>
          <StyledOrderNowWrapper>
            <StyledHeroButton to="/menu">Order Now</StyledHeroButton>
          </StyledOrderNowWrapper>
        </StyledHeaderInner>
      </StyledHeroOverlay>
    </StyledHero>
  );
};

export default Hero;
