import React from 'react';
import styled, { css } from 'styled-components';
import { siteColors } from '../../styles/Variables';

interface Props {
  pageHeader?: boolean;
}
const StyledHTag = styled.h1<Props>`
  border-left: solid 5px ${siteColors.primary};
  padding-left: 10px;
  text-transform: uppercase;

  ${({ pageHeader }) =>
    pageHeader &&
    css`
      font-weight: 300;
    `}
`;

export default StyledHTag;
