import React, { ReactNode, useState, useEffect } from 'react';
import { usePopper } from 'react-popper';
import { Placement as PopperPlacements } from '@popperjs/core';
import styled from 'styled-components';

interface CM extends React.HTMLAttributes<HTMLUListElement> {
  ref?: any;
  fitWidthToContent?: boolean;
  menuBorderRadius?: string;
}

export const PopOutMenuStyled = styled.ul<CM>`
  position: absolute;
  background-color: #fff;
  border: solid 1px #ccc;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
  margin: 0;
  padding: 0;
  width: 300px;
  list-style: none;
  z-index: 99;
  border-radius: ${({ menuBorderRadius }) => menuBorderRadius};
  max-height: 300px;
`;

export interface Props extends React.HTMLAttributes<HTMLUListElement> {
  children?: ReactNode;
  placement?: PopperPlacements;
  referenceElement?: any;
  fitWidthToContent?: boolean;
  menuBorderRadius?: string;
}
const PopOutMenu = React.forwardRef<HTMLUListElement, Props>(
  (props: Props, ref: any) => {
    const {
      placement,
      children,
      referenceElement,
      fitWidthToContent,
      menuBorderRadius = '5px',
      ...rest
    } = props;
    const [popperElement, setPopperElement] = useState<HTMLUListElement | null>(
      null
    );
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
      placement,
      modifiers: [{ name: 'offset', options: { offset: [0, 10] } }],
    });

    useEffect(() => {
      setPopperElement(ref.current);
    }, [popperElement]);

    return (
      <>
        <PopOutMenuStyled
          fitWidthToContent={fitWidthToContent}
          ref={ref}
          menuBorderRadius={menuBorderRadius}
          style={styles.popper}
          {...attributes.popper}
          {...rest}
        >
          {children}
        </PopOutMenuStyled>
      </>
    );
  }
);

export default PopOutMenu;
