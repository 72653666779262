import React from 'react';
import styled, { AnyStyledComponent } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTwitter,
  faInstagram,
  faFacebook,
} from '@fortawesome/free-brands-svg-icons';
import { siteColors } from '../../styles/Variables';

import AWLogo from '../../assets/svgs/awlogo.svg';

const StyledFooter = styled.footer`
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  font-size: 14px;
  position: relative;
  clear: both;
  margin-top: 50px;
`;

const StyledFooterInner = styled.div`
  max-width: 980px;
  margin: 0 auto;
  display: flex;
  div {
    flex: 1 1;
    display: flex;
    align-items: center;
    &:nth-child(2) {
      justify-content: center;
      svg {
        margin-right: 20px;
      }
      @media (max-width: 650px) {
        justify-content: center;
      }
    }
    &:nth-child(3) {
      justify-content: flex-end;
      svg {
        margin-right: 20px;
      }
      @media (max-width: 768px) {
        flex: 1 1 100px;
      }
      @media (max-width: 650px) {
        justify-content: center;
        flex: 1 1;
      }
    }
    @media (max-width: 650px) {
      justify-content: center;
      flex: 1 1;
    }
  }
  @media (max-width: 650px) {
    flex-direction: column;
  }
`;

const StyledLogo = styled(AWLogo as AnyStyledComponent)`
  height: 30px;
  width: auto;
  display: inline-block;
  margin-right: 0px !important;
`;
const Footer = () => {
  return (
    <StyledFooter>
      <StyledFooterInner>
        <div>&copy; 2020 Name of Restaurant</div>
        <div>
          <a href="https://twitter.com/adamwebster">
            <FontAwesomeIcon icon={faTwitter} />
          </a>
          <a href="https://facebook.com/adamwebsterGD">
            <FontAwesomeIcon icon={faFacebook} />
          </a>
          <a href="https://instagram.com/awebsart">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
        </div>
        <div>
          <StyledLogo />
          Designed and Coded by{' '}
          <a
            style={{ marginLeft: 5 + 'px', color: siteColors.primary }}
            href="https://adamwebster.me"
          >
            Adam Webster
          </a>
        </div>
      </StyledFooterInner>
    </StyledFooter>
  );
};

export default Footer;
