import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { MainNavigation } from '../MainNavigation';
import FireLifeLogo from '../../assets/svgs/FireLifeLogo.svg';
const StyledHeader = styled.header`
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(12px);
  padding: 10px;
  position: fixed;
  box-sizing: border-box;
  z-index: 2;
`;

const StyledHeaderInner = styled.div`
  max-width: 980px;
  margin: 0 auto;
  display: flex;
  @media (max-width: 600px) {
    flex-flow: column;
  }
`;

const StyledLogoWrapper = styled(Link)`
  display: flex;
  color: #fff;
  height: 30px;
  flex: 0 0 139px;
  @media (max-width: 600px) {
    flex: 1 1 auto;
    padding-bottom: 10px;
    align-items: flex-start;
  }
  svg {
    width: auto;
  }
`;

const StyledNavWrapper = styled.div`
  display: flex;
  flex: 1 1;
  justify-content: flex-end;
`;

const Header = () => {
  return (
    <StyledHeader>
      <StyledHeaderInner>
        <StyledLogoWrapper to="/">
          <FireLifeLogo />
        </StyledLogoWrapper>
        <StyledNavWrapper>
          <MainNavigation />
        </StyledNavWrapper>
      </StyledHeaderInner>
    </StyledHeader>
  );
};

export default Header;
