import React, { useRef, useState, useEffect, useContext } from 'react';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { PopOutMenu } from '../PopoutMenu';
import { Button } from '@adamwebster/fused-components';
import { siteColors, styleVariables } from '../../styles/Variables';
import { LinkButton } from '../LinkButton';
import { SiteContext } from '../../state';
import { darken, lighten } from 'polished';
import { CartItem } from '../CartItem';

interface SNProps {
  activePage?: string;
}
const StyledNavigation = styled.nav<SNProps>`
  position: relative;
  svg {
    color: #fff;
    text-decoration: none;
    box-sizing: border-box;
  }
  a{
    &[data-content=${({ activePage }) => activePage} i]{
      border-bottom: solid 3px red;
     }
  }

  @media (max-width: 600px) {
    flex: 1 1;
    display: flex;
    align-items: flex-start;
  }
`;

const StyledNavLink = styled(Link)`
  color: #fff;
  padding: 10px;
  box-sizing: border-box;
  text-decoration: none;

  @media (max-width: 600px) {
    flex: 1 1;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
`;

const StyledPopOutMenuInner = styled.div`
  padding: 10px;
  overflow: auto;
`;

const StyledPopOutMenuContentWrapper = styled.div`
  display: flex;
  flex-flow: column;
  max-height: 300px;
`;
const StyledCartBadge = styled.div`
  color: #fff;
  background-color: ${siteColors.primary};
  border-radius: ${styleVariables.borderRadius};
  padding: 3px 10px;
  font-size: 0.85rem;
  display: inline-block;
  border: solid 3px ${lighten(0.2, siteColors.primary)};
  margin-left: 10px;
  font-weight: bold;
`;

const StyledCartButton = styled(Button)`
  border: none;
  margin-top: -3px;
  @media (max-width: 600px) {
    margin-top: 5px;
  }
`;

const StyledEmptyMessage = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  svg {
    margin: 30px 0;
  }
`;

const MainNavigation = () => {
  const menuRef = useRef<HTMLUListElement>(('' as unknown) as HTMLUListElement);
  const targeRef = useRef<HTMLDivElement>(('' as unknown) as HTMLDivElement);
  const buttonRef = useRef<HTMLButtonElement>(
    ('' as unknown) as HTMLButtonElement
  );

  const { globalState } = useContext(SiteContext);
  const [itemsInBag, setItemsInBag] = useState([]);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const items = localStorage.getItem('ItemsInBag');
    if (items) {
      setItemsInBag(JSON.parse(items as string));
    }
  }, [menuOpen]);

  function findUpTag(el: any, tag: any) {
    while (el.parentNode) {
      el = el.parentNode;
      if (el === tag) return el;
    }
    return null;
  }

  const handleClickOutside = (e: MouseEvent): void => {
    const parentOfMenu = findUpTag(e.target, menuRef.current);
    const parentOfButton = findUpTag(e.target, buttonRef.current);
    if (!parentOfMenu && !parentOfButton && e.target !== buttonRef.current) {
      setMenuOpen(false);
    }
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <StyledNavigation activePage={globalState.activePage}>
      <StyledNavLink data-content="home" to="/">
        Home
      </StyledNavLink>
      <StyledNavLink data-content="menu" to="/menu">
        Menu
      </StyledNavLink>
      <StyledNavLink data-content="contact" to="/contact">
        Contact
      </StyledNavLink>
      <span ref={targeRef}>
        <StyledCartButton
          buttonColor={siteColors.primary}
          ref={buttonRef}
          onClick={() => toggleMenu()}
          tabIndex={0}
        >
          <FontAwesomeIcon icon={faShoppingCart} />
          {globalState.cartCount > 0 && (
            <StyledCartBadge>{globalState.cartCount}</StyledCartBadge>
          )}
        </StyledCartButton>
      </span>
      {menuOpen && (
        <PopOutMenu
          fitWidthToContent
          referenceElement={targeRef.current}
          ref={menuRef}
          placement="bottom-end"
        >
          <StyledPopOutMenuContentWrapper>
            <StyledPopOutMenuInner>
              {itemsInBag.length > 0 ? (
                <>
                  {itemsInBag.map((item: any, index) => {
                    return (
                      <CartItem
                        key={item.id + '_' + index}
                        item={item}
                        itemsInBag={itemsInBag}
                        setItemsInBag={(value) => setItemsInBag(value)}
                        index={index}
                      />
                    );
                  })}
                </>
              ) : (
                <>
                  <StyledEmptyMessage>
                    <FontAwesomeIcon
                      icon={faShoppingCart}
                      size="2x"
                      style={{ color: '#ccc' }}
                    />
                    No item added.
                  </StyledEmptyMessage>
                  <LinkButton
                    style={{ display: 'block', margin: '10px auto' }}
                    to="/menu"
                  >
                    VIEW THE MENU
                  </LinkButton>
                </>
              )}
            </StyledPopOutMenuInner>
            {itemsInBag.length > 0 && (
              <LinkButton style={{ margin: '10px' }} to="/payment">
                CHECKOUT
              </LinkButton>
            )}
          </StyledPopOutMenuContentWrapper>
        </PopOutMenu>
      )}
    </StyledNavigation>
  );
};

export default MainNavigation;
