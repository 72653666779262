import React, { ReactNode, useEffect, useState, useContext } from 'react';
import { Header } from '../Header';
import { Hero } from '../Hero';
import { Footer } from '../Footer';
import { GlobalStyles } from '../../styles/GlobalStyles';
import { Alert, CornerDialog, useToast } from '@adamwebster/fused-components';
import styled from 'styled-components';
import { SmallerHero } from '../SmallerHero';
import {
  faFirefox,
  faSafari,
  faChrome,
  faEdge,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ImageModal } from '../ImageModal';
import { SiteContext } from '../../state';
interface SCProps {
  hasHero: boolean;
}
const StyledContent = styled.div<SCProps>`
  max-width: 980px;
  margin: 0 auto;
  padding: ${({ hasHero }) => (hasHero ? '10px' : '50px 10px 10px 10px')};
  position: relative;
  box-sizing: border-box;
`;

const StyledBrowserList = styled.div`
  display: flex;
  width: 100%;
  margin: 30px 0;
  div {
    flex: 1 1;
    display: flex;
    justify-content: center;
    svg {
      display: inline-block;
    }
  }
`;
interface Props {
  children: ReactNode;
  hasHero?: boolean;
}

const windowGlobal: any = typeof window !== 'undefined' && window;

const Layout = ({ children, hasHero = true }: Props) => {
  const [dialogVisible, setDialogVisible] = useState(false);
  const { globalState } = useContext(SiteContext);
  const toast = useToast();
  function isIE() {
    // IE 10 and IE 11
    if (windowGlobal) {
      return /Trident\/|MSIE/.test(window.navigator.userAgent);
    }
    return;
  }

  const setWelcomeRead = () => {
    localStorage.setItem('WelcomeVisible', 'false');
    setDialogVisible(false);
  };

  const goToAdamsSite = () => {
    localStorage.setItem('WelcomeVisible', 'false');
    setDialogVisible(false);
    window.location.href = 'https://adamwebster.me';
  };

  useEffect(() => {
    const welcomeRead = localStorage.getItem('WelcomeVisible');
    if (!welcomeRead) {
      setDialogVisible(true);
    }
  }, []);

  return (
    <>
      <GlobalStyles />
      <Header />
      {hasHero ? <Hero /> : <SmallerHero />}
      <StyledContent hasHero={hasHero}>
        {isIE() && (
          <Alert
            style={{
              position: 'absolute',
              top: '-500px',
              width: 'calc(100% - 20px)',
              zIndex: 9,
              boxSizing: 'border-box',
            }}
            fcStyle="danger"
            title="Internet Explorer is not Supported"
          >
            Internet Explorer 11 or below it is not supported for this site.
            Please update to a modern browser to view this site.
            <StyledBrowserList>
              <div>
                <a
                  title="Firefox"
                  target="_blank"
                  rel="nofollow"
                  style={{ color: 'orange' }}
                  href="https://www.mozilla.org/firefox/new/"
                >
                  <FontAwesomeIcon size="3x" icon={faFirefox} />
                </a>
              </div>
              <div>
                <a
                  title="Safari"
                  target="_blank"
                  rel="nofollow"
                  style={{ color: '#0079b3' }}
                  href="https://www.apple.com/ca/safari/"
                >
                  <FontAwesomeIcon size="3x" icon={faSafari} />
                </a>
              </div>
              <div>
                <a
                  title="Chrome"
                  target="_blank"
                  rel="nofollow"
                  style={{ color: 'red' }}
                  href="https://www.google.com/chrome/"
                >
                  <FontAwesomeIcon size="3x" icon={faChrome} />
                </a>
              </div>
              <div>
                <a
                  title="Edge"
                  target="_blank"
                  rel="nofollow"
                  style={{ color: '#009089' }}
                  href="https://www.microsoft.com/en-us/edge"
                >
                  <FontAwesomeIcon size="3x" icon={faEdge} />
                </a>
              </div>
            </StyledBrowserList>
          </Alert>
        )}
        {children}
      </StyledContent>
      <Footer />
      {dialogVisible && (
        <CornerDialog
          fcStyle="info"
          confirmText="Visit Adam's Site"
          cancelText="Close"
          title="Welcome"
          id="welcome"
          onCloseClick={() => setWelcomeRead()}
          onConfirmClick={() => goToAdamsSite()}
        >
          Hello, this site is just a demo site that was designed and developed
          by Adam Webster. It is not a for a real restaurant and is just for
          demonstration purposes.
        </CornerDialog>
      )}
      {globalState.imageModalOpen && (
        <ImageModal src={globalState.imageModalImageSrc} />
      )}
    </>
  );
};

export default Layout;
