import React, { useContext } from 'react';
import styled from 'styled-components';
import { siteColors, styleVariables } from '../../styles/Variables';
import { SiteContext } from '../../state';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const StyledPrice = styled.span`
  font-size: 0.8rem;
`;

const StyledMenuItem = styled.div`
  display: flex;
  border-bottom: solid 1px #ccc;
  padding: 10px 0;
  line-height: 1rem;
  align-items: center;
  &:last-child {
    border-bottom: none;
  }
`;

const StyledItemTitle = styled.div`
  font-weight: bold;
`;

const StyledMenuItemContent = styled.div`
  flex: 1 1;
`;

const StyledRemoveButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  svg {
    color: ${siteColors.red}!important;
  }
`;

const StyledItemImage = styled.div`
  width: 50px;
  height: 50px;
  border-radius: ${styleVariables.borderRadius};
  margin-right: 15px;
  background-size: cover;
  background-position: center center;
`;

interface Props {
  item: any;
  index: number;
  itemsInBag: any;
  setItemsInBag: (value: any) => void;
}
const CartItem = ({ item, index, itemsInBag, setItemsInBag }: Props) => {
  const { globalState, dispatch } = useContext(SiteContext);

  const removeFromList = (itemIndex: number) => {
    const removeItemList = itemsInBag.filter(
      (item: any, index: number) => index != itemIndex
    );
    setItemsInBag(removeItemList);
    localStorage.setItem('ItemsInBag', JSON.stringify(removeItemList));
    dispatch({ type: 'SET_CART_COUNT', payload: globalState.cartCount - 1 });
  };

  return (
    <StyledMenuItem key={item.id + '_' + index}>
      <StyledItemImage
        aria-label={`Image of ${item.title}`}
        role="img"
        style={{ backgroundImage: `url(${item.imgSrc})` }}
      />
      <StyledMenuItemContent>
        <StyledItemTitle>{item.title}</StyledItemTitle>
        <StyledPrice> {item.price}</StyledPrice>
      </StyledMenuItemContent>
      <StyledRemoveButton onClick={() => removeFromList(index)}>
        <FontAwesomeIcon icon={faTimes} />
      </StyledRemoveButton>
    </StyledMenuItem>
  );
};

export default CartItem;
